export class OstetricaClass {    
  
    constructor(data){
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.birth_date = data.birth_date;
        this.birth_city = data.birth_city;
        this.created_date = data.created_date;
        this.job_qualification_date = data.job_qualification_date;
        this.order = data.order_data;
        this.state = data.state;
    }

    getFormattedBirthDate() {
        if (this.birth_date) {
            const date = new Date(this.birth_date);
            return `${this.formatDate(date, 'dd/mm/yyyy')}`;
        }
        return '';
    }

    getFormattedCreatedDate() {
        if (this.created_date) {
            const date = new Date(this.created_date);
            return `${date.getFullYear()}`;
        }
        return '';
    }

    formatDate(date, format) {
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let year = date.getFullYear();

        if (format === 'dd/mm/yyyy') {
            return `${day}/${month}/${year}`;
        } else if (format === 'yyyy') {
            return `${year}`;
        }
        return '';
    }

    getStateDisplay() {
        return this.state === 'paused' ? 'Sospesa/o' : '-';
    }
}